<!--开通服务-->
<template>
  <div style="padding: 20px;">

    <div style="margin-bottom: 20px;">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">开通服务</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <div style="width: 1100px;background: #ffffff;border-radius: 10px; margin: auto;padding: 20px;">
      <div v-if="buzhou === 0">
        <!--<div style="font-size: 30px;font-weight: 500;color: #000000;text-align: center;margin-top: 30px;">提现申请</div>-->
        <div style="text-align: center;margin-top: 30px;">
          <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">您要开通的服务是：</span>
          <span style="font-size: 18px;font-weight: 500;color: #202033;">白金年卡会员</span>
        </div>
        <div style="margin-top: 30px;text-align: center;">
          <span style="font-size: 16px;font-weight: 500; margin-right: 15px;">开通数量</span>
          <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
        </div>
        <div style="margin-top: 30px;text-align: center;">
          <span style="font-size: 16px;font-weight: 500;color: #4e4e4e;">支付开通服务费：</span>
          <span style="font-size: 18px;font-weight: 700;color: #ff9b05;">200元</span>
        </div>
        <div style="font-size: 14px;font-weight: 400;color: #7e7f8c;margin-top: 60px;">
          注:对于虚拟号码服务,每个订单自动对应几个虚拟号码(客服电话、司机电话、)，用户打入打出都是使用此号码,用户可以用虚拟号拨号，包括司机打给客户客户打给司机客户打给平台平台打给司机服务商购买时可修改虚拟号码的数量条数,条数价格在系统设置中定义。
        </div>
      </div>

      <div v-if="buzhou === 1">
        <div>
          <span style="font-size: 24px;font-weight: 700;color: #202033;">您选择的是XX会员,</span>
          <span style="font-size: 24px;font-weight: 700;color: #FF9B05;">需支付100元</span>
          <span style="font-size: 24px;font-weight: 700;color: #202033;">,请选择支付方式:</span>
        </div>
        <div style="width: 62%;margin: auto;margin-top: 20px;">
          <div class="kuaiyuansu" v-for="(item,index) in zhifuList" :key="index" :class="{kuaiyuansu1:index==isZhifu}" @click="activeItem2(item,index)">
            <img :src="item.img" alt="" style="width: 82px;height: 82px;">
            <span style="display: block;margin-top: 20px;">{{item.label}}</span>
            <img v-if="isZhifu == index" src="@/assets/images/home/order/biaozhi.png" alt="" style="width: 26px;height: 26px;position: absolute;bottom: 0;right: 0">
          </div>
        </div>
        <div style="width: 62%;margin: auto;margin-top: 20px;text-align: center;">
          <el-button style="background: #FF9B05!important;border-color: #FF9B05!important;color: #FFFFFF!important;width: 50%;" @click="nextStep">下一步</el-button>
        </div>
      </div>

    </div>

    <div style="text-align: center; margin-top: 60px;" v-if="buzhou === 0">
      <el-button style="background: #FEA837 !important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="To">同意，去支付</el-button>
      <el-button @click="returnPage">取消</el-button>
    </div>



    <!--支付弹窗-->
    <upgradeMember-edit :data="current" :visible.sync="showEdit"/>

  </div>
</template>

<script>
  import UpgradeMemberEdit from './components/upgradeMember-edit.vue'

  export default {
    components:{
      UpgradeMemberEdit
    },
    data(){
      return{
        num:1,

        buzhou:0,

        zhifuList:[
          {
            label:'微信支付',
            img:require('../../assets/images/home/gerenzhongxin/weixinzhifu.png'),
          },
          {
            label:'支付宝支付',
            img:require('../../assets/images/home/gerenzhongxin/zhifubaozhifu.png'),
          },
          {
            label:'银行汇款',
            img:require('../../assets/images/home/gerenzhongxin/yinhanghuikuan.png'),
          },
        ],
        isZhifu:0,

        current:null,
        showEdit:false,

      }
    },

    methods:{
      handleChange(value) {
        console.log(value);
      },

      //点击返回按钮
      returnPage(){
        this.$router.go(-1);
        localStorage.setItem('showDetails', 'true')
      },

      activeItem2(item,index){
        this.isZhifu = index;
      },

      To(){
        this.buzhou = 1;
      },

      //点击下一步
      nextStep(){
        if(this.isZhifu === 0){
          this.current = '微信支付'
        }else if(this.isZhifu === 1){
          this.current =  '支付宝支付'
        }else {
          this.current = '银行汇款'
        }
        this.showEdit = true;
      },

    }

  }
</script>

<style lang="scss" scoped>
.kuaiyuansu{
  background: #ffffff;
  border: 1px solid #ededf5;
  border-radius: 6px;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 30px 50px 30px 50px;
}
.kuaiyuansu:hover{
  background: #FFFFFF;
  border-color: #FAD08D;
}
.kuaiyuansu1{
  background: #FFFFFF;
  border-color: #FAD08D;
}
</style>
